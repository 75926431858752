ion-content ion-toolbar {
  --background: translucent;
}
.container {
  --background: #7b4397;
  --background: -webkit-linear-gradient(
    to right,
    #dc2430,
    #7b4397
  );
  --background: linear-gradient(
    to right,
    #dc2430,
    #7b4397
  );
}

.fix-width-card{
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
